body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  overflow: hidden;
  background: linear-gradient(328deg, #020136 0%, #1511f1 100%);
}

#explore {
  position: absolute;
  left: 49.8vw;
  top: 30vh;
}
